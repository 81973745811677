import { gsap, Elastic, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { cooperate } from "../js/anims/cooperate.js";
import { fade } from "../js/anims/fade.js";
import { hero } from "../js/anims/hero.js";
import { rows } from "../js/anims/rows.js";
import { simple } from "../js/anims/simple.js";
//import { stars } from "../js/anims/stars.js";
import { wantmore } from "../js/anims/wantmore.js";
//import { topbar } from "../js/anims/topbar.js";
import Lenis from 'lenis'

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {
        document.querySelectorAll('.gs-cooperate') ? cooperate() : false;
        document.querySelectorAll('.gs-fade').length > 0 ? fade() : false;
        document.querySelectorAll('.gs-rows').length > 0 ? rows() : false;
        document.querySelectorAll('.gs-simple').length > 0 ? simple() : false;
        //document.querySelectorAll('.gs-stars') ? stars() : false;
        document.querySelector('.gs-hero-leads') ? hero() : false;
        document.querySelector('.gs-wantmore') ? wantmore() : false;
        //document.querySelector('.js-topbar') ? topbar() : false;
        
        //const lenis = new Lenis()

        // lenis.on('scroll', (e) => {
        //   console.log(e)
        // })
        
        //lenis.on('scroll', ScrollTrigger.update)
        
        // gsap.ticker.add((time)=>{
        //   lenis.raf(time * 1000)
        // })
        
        // gsap.ticker.lagSmoothing(0)
    }
}, false)
